<template>
    <div class="modal-select-reason">
        <ul class="reason-content m-l-20 m-r-20">
            <div
                class="title f-20 m-b-20"
                style="color: #181f39; line-height: 1.4; margin-top: 3.125rem; font-weight: 700"
            >
                {{ options.user.name[0] }}OO님의 스펙은 어떤가요?
            </div>
            <li class="list f-13 m-l-20" style="list-style-type: disc; color: #666b70; line-height: 1.6">
                솔직하게 평가하면 더 잘 맞는 프로필을 전달해드릴 수 있어요!
            </li>
            <li class="list f-13 m-l-20" style="list-style-type: disc; color: #666b70; line-height: 1.6">
                선택 결과는 상대방에게 공개되지 않으며, 수락/거절에 영향을 미치지 않습니다.
            </li>
        </ul>
        <div class="user-detail-spec">
            <UserProfile :user="options.user" :from="'ProfilePhoto'" :type="'spec'" />
        </div>
        <div class="btn-wrap">
            <div class="score-buttons m-b-6" v-for="item in scoringItems" :key="item.id" @click="onClickBtn(item)">
                <button class="score-btn" :class="{ active: item.$$selected }">
                    <img
                        class="face-emoji m-r-6"
                        v-if="item.$$selected"
                        :src="item.srcActive"
                        alt="face-icon"
                        width="24px"
                    />
                    <img class="face-emoji m-r-6" v-else :src="item.src" alt="face-icon" width="24px" />
                    <div class="text-box">{{ item.description }}</div>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import UserProfile from '@/routes/user-detail/components/UserProfile'

export default {
    name: 'ModalSpecScore',
    props: ['options'],
    components: {
        UserProfile,
    },
    data: () => ({
        selectedValue: null,
        initialPage: 0,
        scoringItems: [
            {
                id: 1,
                description: '정말 마음에 들어요',
                src: require(`@/assets/images/icons/ri_emoticon-happy_inactive.png`),
                srcActive: require(`@/assets/images/icons/ri_emoticon-happy_active.png`),
                $$selected: false,
                score: 5,
            },
            {
                id: 2,
                description: '마음에 들어요',
                src: require(`@/assets/images/icons/ri_emoticon-happy-line_inactive.png`),
                srcActive: require(`@/assets/images/icons/ri_emoticon-happy-line_active.png`),
                $$selected: false,
                score: 4,
            },
            {
                id: 3,
                description: '보통이에요',
                src: require(`@/assets/images/icons/ri_emoticon-normal-line_inactive.png`),
                srcActive: require(`@/assets/images/icons/ri_emoticon-normal-line_active.png`),
                $$selected: false,
                score: 3,
            },
            {
                id: 4,
                description: '아쉬워요',
                src: require(`@/assets/images/icons/ri_emoticon-unhappy-line_inactive.png`),
                srcActive: require(`@/assets/images/icons/ri_emoticon-unhappy-line_active.png`),
                $$selected: false,
                score: 2,
            },
            {
                id: 5,
                description: '매우 아쉬워요',
                src: require(`@/assets/images/icons/ri_emoticon-unhappy_inactive.png`),
                srcActive: require(`@/assets/images/icons/ri_emoticon-unhappy_active.png`),
                $$selected: false,
                score: 1,
            },
        ],
    }),
    computed: {
        me() {
            return this.$store.getters.me
        },
        isMe() {
            return this.userId === this.me.id
        },
    },
    methods: {
        async onClickBtn(item) {
            if (item.$$selected) {
                this.$set(item, '$$selected', !item.$$selected)
                this.selectedValue = null
            } else {
                this.scoringItems.forEach(o => this.$set(o, '$$selected', false))
                this.$set(item, '$$selected', !item.$$selected)
                this.selectedValue = item
                setTimeout(() => {
                    this.$emit('close', item.score)
                    this.$emit('close')
                }, 300)
            }
        },
    },
}
</script>
<style scoped lang="scss">
.btn-wrap {
    margin: 0 1.25rem 0.9375rem;
    .score-buttons {
        height: 3rem;
        .score-btn {
            position: relative;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 400;
            font-size: 0.875rem;
            color: #5c5c77;
            background-color: #e8e4e7;
            border: 1px solid #5c5c77;
            border-radius: 0.5rem;
            padding: 0.5rem;
            .text-box {
                width: 6.75rem;
                text-align: left;
            }

            &.active {
                background-color: #151360;
                color: #ffffff;
            }
        }
    }
}
</style>
